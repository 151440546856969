/**
 * Navigation functionality for the search bar.
 *
 * @param  {jQuery}    $          Ensures $ can be used to reference the jQuery
 *                                object, even if using .noConflict() mode.
 * @param  {object}    window
 * @param  {object}    document
 * @param  {undefined} undefined  Do not pass a value here.
 * @return {object}               The navSearch object.
 */
var navSearch = (function($, window, document, undefined) {
    return {
        /**
         * Whether the search is open or not.
         *
         * @type {Boolean}
         */
        isOpen: false,

        /**
         * The duration for the search open/close animation.
         *
         * @type {Number}
         */
        duration: 200,

        /**
         * Initialize the search menu. This is the only method that needs to be
         * called publicly. It will delegate all other tasks.
         *
         * @param  {jQuery} toggle
         * @param  {jQuery} container
         * @param  {jQuery} form
         * @return {boolean}
         */
        init: function(toggle, container, form) {
            if (!(toggle instanceof jQuery) || !(container instanceof jQuery) || !(form instanceof jQuery)) {
                return false;
            }

            this.toggle = toggle;
            this.container = container;
            this.form = form;
            this.input = form.find('.search-field');

            this.manage();
        },

        /**
         * Manage the tasks needed for the search menu functionality.
         *
         * The tasks consist of listening for clicks on the toggle button which
         * should toggle the search menu opened/closed, and closing the menu on
         * escape key clicked event.
         *
         * @return {void}
         */
        manage: function() {
            this.addToggleListener();
            this.addEscapeListener();
        },

        /**
         * Open the search menu.
         *
         * @param  {Number} duration Overrides the duration set for this object.
         * @return {void}
         */
        openSearch: function(duration) {
            if (duration === undefined || duration < 0) {
                duration = this.duration;
            }

            this.lockWindow();
            this.container.fadeIn(duration);
            // Always ensure the input is focused when the form is opened.
            this.input.focus();
            this.isOpen = true;
        },

        /**
         * Close the search menu.
         *
         * @param  {Number} duration Overrides the duration set for this object.
         * @return {void}
         */
        closeSearch: function(duration) {
            if (duration === undefined || duration < 0) {
                duration = this.duration;
            }

            this.container.fadeOut(duration);
            this.input.val('');
            this.freeWindow();
            this.isOpen = false;
        },

        /**
         * Lock the window down so it cannot scroll while the menu is open.
         *
         * @return {void}
         */
        lockWindow: function() {
            // We need to save the window's scroll position, as locking it up
            // will reset the scroll position to the top of the page.
            this.windowPos = $(window).scrollTop();

            $('html, body').css({
                'overflow': 'hidden',
                'height': '100%'
            });
        },

        /**
         * Free the window from its locked state.
         *
         * @return {void}
         */
        freeWindow: function() {
            $('html, body').css({
                'overflow': 'auto',
                'height': 'auto'
            });

            // Once the window is freed, we need to reset its scroll position to
            // the value before the window was locked.
            $(window).scrollTop(this.windowPos);
            this.windowPos = null;
        },

        /**
         * Listen for clicks on the toggle button and handle toggling of the
         * search menu.
         *
         * @return {void}
         */
        addToggleListener: function() {
            var self = this;

            self.toggle.click(function(e) {
                // Toggle may be a button or <a></a> tag.
                e.preventDefault();

                if (self.isOpen) {
                    self.closeSearch();
                } else {
                    self.openSearch();
                }
            });
        },

        /**
         * Listen for escape key press and close the window if it is open.
         *
         * @return {void}
         */
        addEscapeListener: function() {
            var self = this;

            $(document).keyup(function(e) {
                if (e.keyCode === 27 && self.isOpen) {
                    self.closeSearch();
                }
            });
        }
    };
})(jQuery, window, document);
